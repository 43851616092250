import React from "react";

const data = [
  {
    language: "Hindi",
    raw: true,
    labeled: true,
    duration: "512.14 +",
    transcription: "205.05 +",
    maleSpeakers: "58",
    femaleSpeakers: "63",
    totalFiles: "40623 +",
    meta: "_",
  },
  {
    language: "Bengali",
    raw: true,
    labeled: true,
    duration: "615.59 +",
    transcription: "115.79 + ",
    maleSpeakers: "18",
    femaleSpeakers: "28",
    totalFiles: "52462 +",
    meta: "272.24 +",
  },
  {
    language: "Gujarati",
    raw: false,
    labeled: true,
    duration: "96.37 +",
    transcription: "205.05 +",
    maleSpeakers: "44",
    femaleSpeakers: "35",
    totalFiles: "6202 +",
    meta: "_",
  },
  {
    language: "Kannada",
    raw: true,
    labeled: true,
    duration: "349.21 +",
    transcription: "165.79 +",
    maleSpeakers: "53",
    femaleSpeakers: "26",
    totalFiles: "25274 +",
    meta: "112.08 ",
  },
  {
    language: "Malayalam",
    raw: true,
    labeled: true,
    duration: "643.23 +",
    transcription: "147.26 +",
    maleSpeakers: "12",
    femaleSpeakers: "20",
    totalFiles: "46925 +",
    meta: "209.46 + ",
  },
  {
    language: "Marathi",
    raw: true,
    labeled: true,
    duration: "402.62 +",
    transcription: "185.19 +",
    maleSpeakers: "82",
    femaleSpeakers: "61",
    totalFiles: "34369 +",
    meta: "155.75 +",
  },
  {
    language: "Odia",
    raw: true,
    labeled: true,
    duration: "430.58 +",
    transcription: "_",
    maleSpeakers: "10",
    femaleSpeakers: "32",
    totalFiles: "41845 +",
    meta: "_",
  },
  {
    language: "Punjabi",
    raw: true,
    labeled: true,
    duration: "372.14 +",
    transcription: " 136.87 +",
    maleSpeakers: "65",
    femaleSpeakers: "77",
    totalFiles: "35083 +",
    meta: "145.98 +",
  },
  // {
  //   language: "Sanskrit",
  //   raw: true,
  //   labeled: true,
  //   duration: "102.43",
  //   transcription: "205.05",
  //   maleSpeakers: "95",
  //   femaleSpeakers: "110",
  //   totalFiles: "35038",
  //   meta: "_",
  // },
  {
    language: "Tamil",
    raw: false,
    labeled: true,
    duration: "714.24 +",
    transcription: "_",
    maleSpeakers: "116",
    femaleSpeakers: "42",
    totalFiles: "61573 +",
    meta: "_",
  },
  {
    language: "Telugu",
    raw: true,
    labeled: true,
    duration: "566.56 +",
    transcription: "_",
    maleSpeakers: "53",
    femaleSpeakers: "51",
    totalFiles: "37684 +",
    meta: "_",
  },
  // {
  //   language: "Urdu",
  //   raw: true,
  //   labeled: true,
  //   duration: "73.59",
  //   transcription: "_",
  //   maleSpeakers: "36",
  //   femaleSpeakers: "31",
  //   totalFiles: "49201",
  //   meta: "_",
  // },
];

const DataTable = () => {
  return (
    <div className="flex items-center justify-center bg-gradient-to-b bg-[#0F1338] ">
      <div className="w-full max-w-7xl">
        <div className="overflow-x-auto font-thin">
          {" "}
          {/* This container makes the table scrollable on smaller screens */}
          <table className="min-w-full text-center table-auto border-collapse border border-gray-700 text-white">
            <thead>
              <tr className="bg-[#70CBA2] font-thin ">
                <th className="px-4  py-2 border border-gray-600">Languages</th>
                <th className="px-4  py-2 border border-gray-600">Raw Data</th>
                <th className="px-4  py-2 border border-gray-600">
                  Labeled Data
                </th>
                <th className="px-4  py-2 border border-gray-600">
                  Data Duration (Hour)
                </th>
                <th className="px-4  py-2 border border-gray-600">
                  Transcription (Hour)
                </th>
                <th className="px-4  py-2 border border-gray-600">
                  Male Speakers
                </th>
                <th className="px-4  py-2 border border-gray-600">
                  Female Speakers
                </th>
                <th className="px-4 md:px-8 py-2 border border-gray-600">
                  {" "}
                  Files{" "}
                </th>
                <th className="px-4  py-2 border border-gray-600">
                  Metadata (Hour)
                </th>
              </tr>
            </thead>
            <tbody className="bg-[#0F1338]">
              {data.map((row, index) => (
                <tr key={index} className="border border-gray-600">
                  <td className="px-4  py-2 border border-gray-600">
                    {row.language}
                  </td>
                  <td className="px-4 py-2 border border-gray-600">
                    {row.raw ? (
                      <span className="text-green-400 ">Yes</span>
                    ) : (
                      <span className="text-red-600 ">No</span>
                    )}
                  </td>
                  <td className="px-4 py-2 border border-gray-600">
                    {row.labeled ? (
                      <span className="text-green-400 ">Yes</span>
                    ) : (
                      <span className="text-red-600 ">No</span>
                    )}
                  </td>
                  <td className="px-4  py-2 border border-gray-600">
                    {row.duration}
                  </td>
                  <td className="px-4  py-2 border border-gray-600">
                    {row.transcription}
                  </td>
                  <td className="px-4  py-2 border border-gray-600">
                    {row.maleSpeakers}
                  </td>
                  <td className="px-4  py-2 border border-gray-600">
                    {row.femaleSpeakers}
                  </td>
                  <td className="px-4  py-2 border border-gray-600">
                    {row.totalFiles}
                  </td>
                  <td className="px-4  py-2 border border-gray-600">
                    {row.meta}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default DataTable;
