import React from "react";
import india from "../../../assetes/indiamap.svg";

const TableIntro = () => {
  return (
    <div className="bg-[#1a1b3a] text-white py-16">
      <div className="flex flex-col mx-auto md:flex-row items-center  justify-between max-w-7xl">
        {/* Text Section */}
        <div className="md:w-1/2 px-6  mb-8 md:mb-0">
          <h1 className="text-2xl md:text-5xl  font-bold mb-6">
            Audino Multilingual Data Collection
          </h1>
          <p className="text-base md:text-lg font-thin leading-relaxed">
            Audino’s multilingual data collection offers a comprehensive
            resource across various Indian languages. It includes raw and
            labeled audio data, highlighting key details such as total recording
            hours, transcription duration, and speaker distribution. This
            dataset is ideal for training speech recognition models, with a
            balanced representation of male and female speakers in each
            language.
          </p>
        </div>

        {/* Image Section */}
        <div className="md:w-1/2 flex justify-center">
          <img src={india} alt="India Map" className="w-full max-w-md" />
        </div>
      </div>
    </div>
  );
};

export default TableIntro;
