import React from "react";
import { AiFillStar } from "react-icons/ai";
const About = () => {
  return (
    <section
      id="home"
      className=" bg-[#0F1338]  overflow-hidden z-10 pt-[80px] pb-[60pxpx] md:pt-[100px] md:pb-[80px] xl:pt-[120px] xl:pb-[100px] 2xl:pt-[150px] 2xl:pb-[120px]"
    >
      <div className="">
        <div className="flex flex-wrap mx-[-16px]">
          <div className="w-full px-6 md:px-4">
            <div className="mx-auto text-center  delay-200">
              <div className="  mb-6 text-center visible">
                <span className=" mb-4 font-thin text-sm inline-flex text-center bg-[#0F1338]   pr-5 pl-3  shadow-[#70CBA2] shadow-sm items-center gap-2 py-2 px-4.5 rounded-full">
                  <AiFillStar className="text-yellow-500 " />

                  <span className="text-white"> Our Journey </span>
                </span>
              </div>
              <h1 className="bg-gradient-to-r from-[#70CBA2] font-bold via-blue-400 to-purple-600 bg-clip-text text-transparent text-3xl sm:text-4xl md:text-6xl leading-tight sm:leading-tight md:leading-tight mb-7">
                Uncover the journey of Audino
              </h1>
              <p className=" text-white font-thin text-lg text-body-color   max-w-2xl  mx-auto">
                AUDINO, where innovation converges with simplicity in the realm
                of Natural Language Processing (NLP). Our SaaS platform is a
                trailblazer, reshaping the landscape of language technology. At
                AUDINO, we believe in breaking barriers and democratizing NLP,
                empowering developers worldwide to create advanced language
                processing solutions effortlessly.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
