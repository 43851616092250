import React from "react";
import { Link } from "react-router-dom";

const Getintouch = () => {
  return (
    <div className="bg-[#0F1338] text-white py-16 border-b-[1px] border-white px-6">
      <h2 className=" text-2xl md:text-5xl font-bold text-center mb-4">
        Get in Touch
      </h2>
      <p className="text-center  font-thin text-base sm:text-lg md:text-xl  mb-8">
        For more information or inquiries about our dataset, feel free to reach
        out. Click the button below to visit our Contact page and connect with
        us directly.
      </p>

      <div className="flex justify-center">
        <Link
          to="/contact"
          className="bg-[#8fcdb6] text-white font-medium py-2 px-6 rounded-md hover:bg-[#7abfa6] transition duration-300"
        >
          Contact Us
        </Link>
      </div>
    </div>
  );
};

export default Getintouch;
