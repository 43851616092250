import React from "react";
import Navbar from "../../components/Navbar";
import Datasummary from "./Component/Datasummary";
import DataTable from "./Component/DataTable";
import TableIntro from "./Component/TableIntro";
import Getintouch from "./Component/Getintouch";
import Footer from "../../components/Footer";

const Dataset = () => {
  return (
    <>
      <Navbar />
      <section className="bg-[#0F1338] text-white">
        <div className="mx-auto px-6 sm:px-10 py-16 sm:py-20 flex justify-center">
          <div className="mx-auto text-center">
            <div className="relative">
              <h1 className="bg-gradient-to-r from-[#70CBA2] via-blue-400 to-purple-600 bg-clip-text text-3xl font-extrabold text-transparent sm:text-4xl  md:text-6xl leading-tight sm:leading-snug md:leading-tight  pb-2">
                Unlock the Power of Indian <br className="hidden sm:block" />
                Languages with Our Open-Source{" "}
                <br className="hidden sm:block" />
                Multilingual Dataset
              </h1>
              <p className="mx-auto mt-4 max-w-3xl font-thin text-lg sm:mt-6">
                Explore Audio-Labeled And Transcribed Data In Various Languages,
                Designed For AI Models, Product Development, Speech Processing
                Tools, Language Research, And Other Advanced Applications.
              </p>
            </div>
          </div>
        </div>
      </section>

      <Datasummary />
      <TableIntro />
      <DataTable />
      <Getintouch />
      <Footer />
    </>
  );
};

export default Dataset;
