import React from "react";
const stats = [
  { title: "Languages", value: "10 +" },
  { title: "Total Data Duration ", value: "4702.68 (hr) + " },
  { title: "Total Transcription Duration ", value: "11161.0 (hr) + " },
  { title: "Male Speakers", value: "511 +" },
  { title: "Female Speaker", value: "435 +" },
  { title: "Total Files", value: "3,82,040 +" },
];

const Datasummary = () => {
  return (
    <div className="flex items-center justify-center py-20 bg-gradient-to-b from-[#121B61] to-[#27306f]">
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-4 w-full text-center max-w-5xl px-4">
        {stats.map((stat, index) => (
          <div
            key={index}
            className={`transition-colors  duration-300 
            bg-[#2A3482] text-slate-300 hover:bg-[#3C4694] 
            flex flex-col items-center rounded-lg justify-center gap-6 h-[180px] md:h-[200px] w-full p-4 sm:w-[17rem] md:w-[20rem]`}
          >
            <h3 className="text-md md:text-lg  text-center mx-2">
              {stat.title}
            </h3>
            <p className=" text-2xl md:text-3xl font-bold">{stat.value}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Datasummary;
