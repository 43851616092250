import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./Pages/Home/Home";
import Aboutus from "./Pages/Aboutus/Aboutus";
import Contactus from "./Pages/Contactus/Contactus";
import Custommodal from "./Pages/CustomModal/Custommodal";
import Blogs from "./components/Blogs";
import Blogs1 from "./components/Blogs1";
import Blogs2 from "./components/Blogs2";
import Blogs3 from "./components/Blogs3";
import Blogs4 from "./components/Blogs4";
import Dataset from "./Pages/Dataset/Dataset";

import BlogPages from "./Pages/BlogsPage/BlogPages";
import Blogs5 from "./components/Blogs5";
import Blogs6 from "./components/Blogs6";
import Blogs7 from "./components/Blog7";
import Blogs8 from "./components/Blog8";
import Blogs9 from "./components/Blog9";
import Blogs10 from "./components/Blog10";
import ScrollToTop from "./hooks/scrollToTop";

function App() {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<Aboutus />} />
        <Route path="/contact" element={<Contactus />} />
        <Route path="/custom-model" element={<Custommodal />} />
        <Route path="/dataset" element={<Dataset />} />
        <Route path="/blogs" element={<BlogPages />} />
        <Route path="/blogs/1" element={<Blogs />} />
        <Route path="/blogs/2" element={<Blogs1 />} />
        <Route path="/blogs/3" element={<Blogs2 />} />
        <Route path="/blogs/4" element={<Blogs3 />} />
        <Route path="/blogs/5" element={<Blogs4 />} />
        <Route path="/blogs/6" element={<Blogs5 />} />
        <Route path="/blogs/7" element={<Blogs6 />} />
        <Route path="/blogs/8" element={<Blogs7 />} />
        <Route path="/blogs/9" element={<Blogs8 />} />
        <Route path="/blogs/10" element={<Blogs9 />} />
        <Route path="/blogs/11" element={<Blogs10 />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
